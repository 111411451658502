import { create } from "zustand";

import { persist } from "zustand/middleware";

export const useResumeStore = create(
  persist(
    (set) => ({
      resume: undefined,
      editResume: false,
      CV: undefined,
      editCV: false,
      prefilledResumeName: undefined,
      dataset: undefined || [],
      dataSetId: "",
      myPitch: undefined,
      myPrepRole: undefined,
      // selectedDataSetName: false,
      // handleMySelectedDataSetName: (selectedDataSetName) => {
      //   set(() => ({
      //     selectedDataSetName: !selectedDataSetName,
      //   }));
      // },
      toggleModal: false,
      handleToggleTrue: () => {
        set(() => ({
          toggleModal: true,
        }));
      },
      handleToggleFalse: () => {
        set(() => ({
          toggleModal: false,
        }));
      },
      handleMyDataSet: (dataset) => {
        set(() => ({
          dataset: dataset,
          dataSetId: dataset._id,
        }));
      },
      handleMyDataClose: (dataset) => {
        set(() => ({
          dataset: [],
        }));
      },
      handleMyPitch: (pitch) => {
        const updatedPitch = {
          ...pitch,
          content: undefined,
          resumeTemplate: undefined,
          user: undefined,
          resumeImage: pitch.thumbnailUrl,
        };
        set(() => ({
          myPitch: updatedPitch,
        }));
      },
      handleResume: (resume) => {
        set(() => ({
          resume: resume,
        }));
      },
      handleResumeEdit: (resume) => {
        const updatedResume = {
          ...resume,
          history: undefined,
          content: undefined,
          // code: resume.code,
          // resumeTemplate: { code: resume?.resumeTemplate?.code },
        };
        set(() => ({
          resume: updatedResume,
          editResume: true,
        }));
      },
      handleResumeEditClose: () => {
        set(() => ({
          editResume: false,
          prefilledResumeName: undefined,
          resume: "",
        }));
      },
      handleCV: (CV) => {
        set(() => ({
          CV: CV,
        }));
      },
      handleCVEdit: (CV) => {
        set(() => ({
          CV: CV,
          editCV: true,
        }));
      },
      handleCVEditClose: () => {
        set(() => ({
          editCV: false,
        }));
      },
      handleMyPrepRole: (value) => {
        set(() => ({
          myPrepRole: value,
        }));
      },
    }),
    {
      name: "resume",
    }
  )
);

export default useResumeStore;
